import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import Post, { PostProps } from '../components/Post/Post';
import SEO from '../components/SEO/SEO';

interface PostQuery {
  markdownRemark: PostProps;
}

interface BlogTemplateProps {
  data: PostQuery;
}

const BlogTemplate = ({ data }: BlogTemplateProps): React.ReactElement => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Post html={html} frontmatter={frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default BlogTemplate;
