import React from 'react';

export interface PostFrontmatter {
  title: string;
}

export interface PostProps {
  html: string;
  frontmatter: PostFrontmatter;
}

const Post = (post: PostProps): React.ReactElement => {
  const { frontmatter, html } = post;
  return (
    <div className="post">
      <h1>{frontmatter.title}</h1>
      {/* eslint react/no-danger: 0 */}
      <div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default Post;
